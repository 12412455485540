"use strict";

const removeTabIndex = function (event) {
  this.removeAttribute('tabindex');
  this.focus();
};

const windowhashchange = function (event) {
  const hash = window.location.hash.replace(/^#/, '');

  if (hash != "") {
    let hashtag = document.getElementById(hash);
    hashtag.setAttribute('tabindex', -1);
    hashtag.on('blur', removeTabIndex, false);
    hashtag.on('focusout', removeTabIndex, false);
  }
  else {
    let header = document.getElementById('header');
    header.setAttribute('tabindex', -1);
    header.on('blur', removeTabIndex, false);
    header.on('focusout', removeTabIndex, false);
  }

};

//Document Ready
document.addEventListener("DOMContentLoaded", function () {

  // hash focus onload
  if (document.getElementById(document.location.hash) !== null) {
    const myAnchor = document.getElementById(document.location.hash);

    myAnchor.setAttribute('tabindex', -1);
    myAnchor.on('blur', removeTabIndex, false);
    myAnchor.on('focusout', removeTabIndex, false);
  }

  // hash focus inline
  document.on('hashchange', windowhashchange, false);
});