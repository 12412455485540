"use strict";

document.addEventListener("DOMContentLoaded", function () {
  //Document Ready
  // primary nav
  document.querySelector('.nav-primary').accessibleNav({
    desktop: 1024,
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });

  // //Nav Functionality - Start
  document.querySelector('.nav-primary .nav-toggle').on('click', function () {
    var e = document.querySelector('body');

    if (e.classList.contains('active-menu')) {
      e.classList.remove('active-menu');
    } else {
      e.classList.add('active-menu');
    };
  });

  document.querySelectorAll('.nav-primary li.has_children').forEach((navItem) => {
    navItem.on('click', function () {
      //Toggle nav-open class on clicked nav item if it has children
      ((navItem.classList.contains('nav-open')) ? navItem.classList.remove('nav-open') : navItem.classList.add('nav-open'));
    });
  });

  //Search Functionality - Start
  document.querySelector('.search-toggle').on('click', function () {
    var searchEl = document.querySelector('.search-box');
    var searchToggle = document.querySelector('.search-toggle');

    ((searchEl.classList.contains('active')) ? searchEl.classList.remove('active') : searchEl.classList.add('active'));

    ((searchToggle.classList.contains('active')) ? searchToggle.classList.remove('active') : searchToggle.classList.add('active'));

  });

  document.querySelector('.search-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      var searchEl = document.querySelector('.search-box');
      var searchToggle = document.querySelector('.search-toggle');

      ((searchEl.classList.contains('active')) ? searchEl.classList.remove('active') : searchEl.classList.add('active'));

      ((searchToggle.classList.contains('active')) ? searchToggle.classList.remove('active') : searchToggle.classList.add('active'));
    }
  });

  document.querySelector('body').addEventListener('scroll', function (e) {
    let scroll = document.querySelector('body').scrollTop;
    if (scroll > 150) {
      document.querySelector('header').classList.add('stuck');
    } else {
      document.querySelector('header').classList.remove('stuck');
    }
  });

  //Scroll to Top
  // document.querySelector('body').addEventListener('scroll', function (e) {
  //   let scroll = document.querySelector('body').scrollTop;

  //   if (scroll >= 500 && scroll <= 700) {
  //     document.querySelector('.scroll-to-top').style.opacity = 1;
  //     document.querySelector('header').classList.add('stuck');
  //     document.querySelector('header').classList.remove('show');
  //   } else if (scroll < 500) {
  //     document.querySelector('header').classList.remove('stuck');
  //     document.querySelector('.scroll-to-top').style.opacity = 0;
  //   } else if (scroll > 505) {
  //     document.querySelector('header').classList.add('show');
  //   }
  // });

  // document.querySelector('.scroll-to-top').on('click', function () {
  //   scrollToTop();
  // });

  const chatBubbleHandle = document.createElement("span");
  document.querySelector('#make-a-difference').appendChild(chatBubbleHandle);


  // document.querySelector('#make-a-report .a1').classList.add('nav-button');

});