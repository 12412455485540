"use strict";
//Document Ready
document.addEventListener("DOMContentLoaded", function () {

  //Show/Hide/Toggle Functionality
  Object.prototype.hide = function () {
    this.classList.add('hide');
    return this;
  };

  Object.prototype.show = function () {
    this.classList.remove('hide');
    return this;
  };

  Object.prototype.toggle = function () {
    if (this.classList.contains('hide')) {
      this.classList.remove('hide');
    } else {
      this.classList.add('hide');
    }
    return this;
  };

  //Object.prototype.classList.add()

  //Allow chaining from class Lists
  // function classList(elt) {
  //   var list = elt.classList;

  //   return {
  //     toggle: function (c) { list.toggle(c); return this; },
  //     add: function (c) { list.add(c); return this; },
  //     remove: function (c) { list.remove(c); return this; }
  //   };

  // }
});

//Wrap parent element in wrapper html
function wrapInner(parent, wrapper) {
  if (typeof wrapper === "string") {
    wrapper = document.createElement(wrapper);
  }

  let el = parent.appendChild(wrapper);

  while (parent.firstChild !== wrapper) {
    wrapper.appendChild(parent.firstChild);
  }
}

function scrollToTop() {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    requestAnimationFrame(scrollToTop);
    document.querySelector('body').scrollTo(0, c - c / 8);
  }
}

//Live event handling
Object.prototype.on = function (evt, fnc, opt) {
  const el = this;

  var options = Object.assign({
    option: false
  }, opt);

  document.addEventListener(evt, function (e) {
    if (e.target === el) {
      fnc(e);
    }
  }, options.option);
};

/*
//Example of using .on function

document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('li').forEach((l) => {
    l.on('click', function (e) {
      alert('test');
    });
  });
});
*/