"use strict";
// automatic accessible nav
// include this file in your gulp file and then initialize on any PARENT element of any/or multiple navs

// parameters
// desktop: pixel value without the 'px', set to desktop nav size
// spans: 'show'|'hide',
// level2position: 'horizontal-left'|'horizontal-right'|'vertical-top'|'vertical-bottom',
// level3position: 'horizontal-left'|'horizontal-right'|'vertical-top'|'vertical-bottom'

// example
// $('.nav-primary').accessibleNav({
// desktop: 1025,
//   spans: 'hide',
//   level2position: 'vertical-bottom',
//   level3position: 'horizontal-right'
// });


// insert togglers
function togglers(accessibleNavContainer) {
  let navItems = accessibleNavContainer.querySelectorAll('li');

  // add focusable spans to each list item with a child list
  navItems.forEach((navItem) => {
    if (navItem.classList.contains('has_children')) {
      const togglerLabel = navItem.querySelectorAll('a')[0];

      const togglerLabelText = `<span class="toggler"
                                    tabindex="0"
                                    aria-label="Press enter to view child pages of ${togglerLabel.text}"></span>`;

      togglerLabel.insertAdjacentHTML("afterend", togglerLabelText);
    }
  });
}

// toggler click functions
function togglerClick(screen, desktop) {
  // open and close subnav when toggler is clicked
  if (screen >= desktop) {
    document.querySelectorAll('span.toggler').forEach((el) => {
      el.on('click', function () {
        if (this.nextElementSibling.classList.contains('open')) {
          this.classList.remove('open');
          this.nextElementSibling.classList.remove('open');
          this.focus();
        } else {
          document.querySelectorAll('span.toggler').forEach((el) => {
            if (el != this) {
              el.classList.add('open');
              el.nextElementSibling.classList.add('open');
            }
          });
        }
      });
    });
  } else {
    document.querySelectorAll('span.toggler').forEach((el) => {
      el.on('click', function () {
        if (this.nextElementSibling.classList.contains('open')) {
          this.classList.remove('open');
          this.nextElementSibling.classList.remove('open');
          this.focus();
        } else {
          this.classList.add('open');
          this.nextElementSibling.classList.add('open');
        }
      });
    });
  }
}

function toggleSubnav(selector) {
  selector.hide().classList.remove('open');
  selector.parentElement.querySelector('a.focus').classList.remove('focus');
  selector.parentElement.querySelector('a.focus').nextElementSibling.focus();
}

// toggler key functions
function togglerKeys(accessibleNavContainer, spans) {
  // open subnav if enter key is pressed when toggler is focused
  document.querySelectorAll('span.toggler').forEach((el) => {
    el.on('keypress', function (e) {
      if (e.which === 13) {
        if (this.nextElementSibling.classList.contains('open')) {
          this.previousElementSibling.classList.remove('focus');
          this.parentElement.querySelector('ul').hide().classList.remove('open');
          this.focus();
        } else {
          this.previousElementSibling.classList.add('focus');
          this.parentElement.querySelector('ul').show().classList.add('open');
          this.nextElementSibling.querySelector('a').focus();
        }
      }
    });
  });
  // close subnav if escape key is pressed when toggler is focused
  document.querySelectorAll('span.toggler').forEach((el) => {
    el.on('keyup', function (e) {
      if (e.keyCode === 27) {
        this.previousElementSibling.classList.remove('focus');
        this.parentElement.querySelector('ul').hide().classList.remove('open');
      }
    });
  });
  // if span parameter is set to 'hide'
  if (spans === 'hide') {
    // hide togglers
    let navItems = accessibleNavContainer.querySelectorAll('span.toggler');

    navItems.forEach((navItem) => {
      navItem.classList.add('hide');
    });

    // when tab is pressed if a nav item with children is focus show the toggler or if focusout hide the toggler
    document.on('keyup', function (e) {
      if (e.which === 9 && !e.shiftKey) {
        accessibleNavContainer.querySelectorAll('ul > li.has_children > a').forEach((el) => {
          el.on('focus', function (e) {
            el.nextElementSibling.classList.add('visible');
            el.nextElementSibling.classList.remove('hide');
          });
        });
        accessibleNavContainer.querySelectorAll('ul > li > span.toggler').forEach((el) => {
          el.on('focusout', function (e) {
            if (!(el.previousElementSibling.classList.contains('focus'))) {
              el.classList.remove('visible');
              el.classList.add('hide');
            }
          });
        });
      }
    });
  }
}

// link key functions
function linkKeys(accessibleNavContainer, spans, level2position, level3position) {
  // arrows to focus through subnav
  accessibleNavContainer.querySelectorAll('ul ul a').forEach((el) => {
    el.on('keydown', function (e) {
      if (e.keyCode === 40) {
        if (el.parentElement.nextElementSibling != null) {
          el.parentElement.nextElementSibling.querySelector('a').focus();
        }
        e.preventDefault();
      }
      if (e.keyCode === 38) {
        if (el.parentElement.previousElementSibling != null) {
          el.parentElement.previousElementSibling.querySelector('a').focus();
        }
        e.preventDefault();
      }
    });
  });

  // if a subnav element is focused and escape key is pressed, collapse that subnav and focus back on the toggler
  document.on('keyup', function (e) {
    accessibleNavContainer.querySelectorAll('ul ul a, ul ul span.toggler').forEach((el) => {
      if (document.activeElement === el) {
        if (e.keyCode === 27) {
          el.closest('ul').classList.remove('open');
          el.closest('ul').parentElement.querySelector('a.focus').classList.remove('focus');
          el.closest('ul').parentElement.querySelector('a').nextElementSibling.focus();

          // if (el.classList.contains('hide')) {
          //   el.classList.remove('hide');
          // } else {
          //   el.classList.add('hide');
          // }
        }
      }
    });
  });

  // if first link in level2 subnav is focused and appropriate key is pressed, collapse that subnav and focus back on the toggler
  if (accessibleNavContainer.querySelectorAll('.ul2 > li > a')[0].length > 0) {
    accessibleNavContainer.querySelectorAll('.ul2 > li > a')[0].on('keydown', function (e) {
      if (level2position === 'vertical-bottom' & e.keyCode === 38) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level2position === 'vertical-top' & e.keyCode === 40) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level2position === 'horizontal-left' & e.keyCode === 39) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level2position === 'horizontal-right' & e.keyCode === 37) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else {
        if (e.keyCode === 38) {
          toggleSubnav(this.closest('ul'));
          e.preventDefault();
        }
      }
    });
  }

  // if first link in level3 subnav is focused and up key is pressed, collapse that subnav and focus back on the toggler
  if (accessibleNavContainer.querySelectorAll('.ul3 > li > a')[0] > 0) {
    accessibleNavContainer.querySelectorAll('.ul3 > li > a')[0].on('keydown', function (e) {
      if (level3position === 'vertical-bottom' && e.keyCode === 38) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level3position === 'vertical-top' && e.keyCode === 40) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level3position === 'horizontal-left' && e.keyCode === 39) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (level3position === 'horizontal-right' && e.keyCode === 37) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      } else if (e.keyCode === 38) {
        toggleSubnav(this.closest('ul'));
        e.preventDefault();
      }
    });
  }

  // when focusing out of last link or toggler in list
  // ul2
  document.querySelectorAll('.ul2 > li:last-child > a').forEach((el) => {
    el.on('focusout', function (e) {
      if (this.nextElementSibling != null) {
        if (this.nextElementSibling.length) {
          this.nextElementSibling.focus();
        } else {
          if (spans === 'hide') {
            this.closest('ul').parentElement.querySelector('span.toggler').hide().classList.remove('visible');
          }
          this.closest('ul').hide().classList.remove('open');
          this.closest('ul').parentElement.querySelector('a.focus').classList.remove('focus');
          this.closest('ul').parentElement.parentElement.nextElementSibling('li > a').focus();
        }
      }
    });
  });

  if (accessibleNavContainer.querySelectorAll('.ul2 > li:last-child > a + span.toggler').length > 0) {
    accessibleNavContainer.querySelectorAll('.ul2 > li:last-child > a + span.toggler')[0].on('focusout', function () {
      if (spans === 'hide') {
        this.closest('ul').parentElement.find('span.toggler').classList.remove('visible').hide();
      }
      this.closest('ul').classList.remove('open').hide().parentElement.querySelector('a.focus').classList.remove('focus').parentElement.nextElementSibling('li > a').focus();
    });
  }

  // ul3
  if (accessibleNavContainer.querySelectorAll('.ul3 > li:last-child > a').length > 0) {
    accessibleNavContainer.querySelectorAll('.ul3 > li:last-child > a')[0].on('focusout', function () {
      if (this.nextElementSibling.length) {
        this.nextElementSibling.focus();
      } else {
        this.closest('ul').classList.remove('open').hide().parentElement.querySelector('a.focus').classList.remove('focus').parentElement.nextElementSibling('li > a').focus();
      }
    });
  }

  if (accessibleNavContainer.querySelectorAll('.ul3 > li:last-child > a + span.toggler').length > 0) {
    accessibleNavContainer.querySelectorAll('.ul3 > li:last-child > a + span.toggler')[0].on('focusout', function () {
      this.closest('ul').classList.remove('open').hide().parentElement.querySelector('a.focus').classList.remove('focus').parentElement.nextElementSibling('li > a').focus();
    });
  }
}

// clicks outside of the nav elements
function outsideClicks(accessibleNavContainer, spans) {
  // when clicking outside of any element within or OF nav-primary, close the subnavs
  document.on('click', function (e) {
    if (e.target.closest('.nav-primary') === null) {
      if (window.getComputedStyle(accessibleNavContainer.querySelector('ul ul')).display !== 'none') {
        accessibleNavContainer.querySelector('ul ul').hide().classList.remove('open');
      }
      // if span parameter is set to 'hide'
      if (spans === 'hide') {
        document.querySelectorAll('ul > li > span.toggler').forEach((el) => {
          el.hide();
        });
      }
    }
  });
}

//Document Ready
document.addEventListener("DOMContentLoaded", function () {

  Object.prototype.accessibleNav = function (options) {

    // default plugin settings
    const settings = Object.assign({
      desktop: 1025,
      spans: 'show',
      level2position: 'vertical-bottom',
      level3position: 'horizontal-right'
    }, options);

    // gather plugin settings
    const accessibleNavContainer = this;
    const desktop = settings.desktop;
    let screen, spans, level2position, level3position;

    // if (mobile === null && phone === null && tablet === null) {
    if (window.innerWidth >= desktop) {
      spans = settings.spans;
      level2position = settings.level2position;
      level3position = settings.level3position;
    } else {
      spans = 'show';
      level2position = 'vertical-bottom';
      level3position = 'vertical-bottom';
    }

    let resizeTimer;

    window.on('resize', function (e) {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        screen = window.innerWidth;
      }, 100);
    });

    // initialize functions in order
    togglers(accessibleNavContainer);
    togglerClick(screen, desktop);
    togglerKeys(accessibleNavContainer, spans);
    linkKeys(accessibleNavContainer, spans, level2position, level3position);
    outsideClicks(accessibleNavContainer, spans);

    return this;
  };
});